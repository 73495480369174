$body-bg: #303030;
$body-color: #fff;

// $theme-colors: (
//   "primary": #0074d9,
//   "danger": #ff4136,
// );

// $theme-colors: (
//   "custom-color": #900,
// );

// --------------------------------- modal bootstrap  ---------------------------------

$modal-content-bg: $body-bg;
$modal-content-border-color: rgba($body-color, 0.2);
$modal-header-border-color: rgba($body-color, 0.2);
$modal-footer-border-color: rgba($body-color, 0.2);


// --------------------------------- grid bootstrap  ----------------------------------
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  mdlg: 880px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);