/* You can add global styles to this file, and also import other style files */

@use "@angular/material" as mat;
// @use "@angular/material" as mat;

@include mat.core();

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
body {
  background-color: #303030;
  color: #fff;
}

$my-primary: mat.m2-define-palette(mat.$m2-blue-palette, 500);

// $my-theme: mat.define-light-theme(
//   (
//     color: (
//       primary: $my-primary,
//       //  accent: $my-accent,
//       //  warn: $my-warn,,,
//     ),
//     //  typography: mat.define-typography-config(),
//     //  density: 0,,,
//   )
// );

// Define a theme.
// $primary: theming.define-palette(palette.$purple-palette, 700, 500, 800);
$accent: mat.m2-define-palette(mat.$m2-green-palette, A200, A100, A400);

$theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: $my-primary,
      accent: $accent,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

// Include all theme styles for the components.
@include mat.all-component-themes($theme);

// @include typography.typography-hierarchy($theme);

@import "src/scss/global_variables.scss";
// @import "src/scss/global_variables.scss"
// moved to src/scss/global_variables.scss
// // $body-bg: #303030;
// // $body-color: #fff;
//
// // // $theme-colors: (
// // //   "primary": #0074d9,
// // //   "danger": #ff4136,
// // // );
//
// // // $theme-colors: (
// // //   "custom-color": #900,
// // // );
//
// // // --------------------------------- modal bootstrap  ---------------------------------
//
// // $modal-content-bg: $body-bg;
// // $modal-content-border-color: rgba($body-color, 0.2);
// // $modal-header-border-color: rgba($body-color, 0.2);
// // $modal-footer-border-color: rgba($body-color, 0.2);

// $modal-inner-padding: $spacer;

// $modal-footer-margin-between: 0.5rem;

// $modal-dialog-margin: 0.5rem;
// $modal-dialog-margin-y-sm-up: 1.75rem;

// $modal-title-line-height: $line-height-base;

// $modal-content-color: null;
// $modal-content-bg: $white;
// $modal-content-border-color: rgba($black, 0.2);
// $modal-content-border-width: $border-width;
// $modal-content-border-radius: $border-radius-lg;
// $modal-content-inner-border-radius: subtract(
//   $modal-content-border-radius,
//   $modal-content-border-width
// );
// $modal-content-box-shadow-xs: $box-shadow-sm;
// $modal-content-box-shadow-sm-up: $box-shadow;

// $modal-backdrop-bg: $black;
// $modal-backdrop-opacity: 0.5;
// $modal-header-border-color: $border-color;
// $modal-footer-border-color: $modal-header-border-color;
// $modal-header-border-width: $modal-content-border-width;
// $modal-footer-border-width: $modal-header-border-width;
// $modal-header-padding-y: $modal-inner-padding;
// $modal-header-padding-x: $modal-inner-padding;
// $modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

// $modal-sm: 300px;
// $modal-md: 500px;
// $modal-lg: 800px;
// $modal-xl: 1140px;

// $modal-fade-transform: translate(0, -50px);
// $modal-show-transform: none;
// $modal-transition: transform 0.3s ease-out;
// $modal-scale-transform: scale(1.02);
// --------------------------------- modal bootstrap  ---------------------------------

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

// body {
//   background-color: #303030;
//   color: #fff;
// }

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-y-hidden {
  overflow-y: hidden;
}


.btn-transparent {
  background-color: transparent;
  color: $body-color;
  border: 0;
  &[disabled] {
    color: rgba($body-color, 0.5);
  }
}

.border.border-black, .border-start.border-black, .border-end.border-black, .border-top.border-black, .border-bottom.border-black {
  border-color: black !important;
}

.border.border-gray, .border-start.border-gray, .border-end.border-gray, .border-top.border-gray, .border-bottom.border-gray {
  border-color: gray !important;
}
.border.border-grey, .border-start.border-grey, .border-end.border-grey, .border-top.border-grey, .border-bottom.border-grey {
  border-color: grey !important;
}

.italic {
  font-style: italic;
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #ffffff;
  --mdc-snackbar-supporting-text-color: #ffffff;

  &.error {
    --mdc-snackbar-container-color: #f23a2f;
  }

  &.success {
    --mdc-snackbar-container-color: #43a446;
  }
}

.bk-light-gray, .bk-light-grey {
  background-color: rgba($body-bg, 0.2);
}

.bk-dark-gray,
.bk-dark-grey {
  background-color: rgba($body-bg, 0.8);
}

.bk-gray,
.bk-grey {
  background-color: #424242;
}